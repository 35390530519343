import { SuggestWidget } from "@/widgets/suggest";
import { useEffect } from "react";

export const SuggestPage = () => {
  useEffect(() => {
    document.title = "Буцаалтын хуудас биелүүлэх";
  }, []);

  return (
    <div className="page">
      <SuggestWidget />
    </div>
  );
};
