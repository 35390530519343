import { useMemo, useState } from "react";

import { Button, Col, message, Popover, Row, Space, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { DeleteFilled, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import sdk from "@/sdk";
import { format } from "date-fns";

export const SuggestWidget = () => {
  const [deleteId, setDeleteId] = useState("");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getSuggestList"],
    queryFn: async () => sdk.suggestions(),
  });

  const { data: portfolio, isLoading: portfolioLoading } = useQuery({
    queryKey: ["getPortfolio"],
    queryFn: async () => sdk.getAllPortfolios(),
  });

  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationKey: ["deleteSuggest"],
    mutationFn: async () => sdk.deleteSuggestion({ _id: deleteId }),
    onError: (err) => {
      if (err instanceof Error) {
        message.error(err.message, 2);
      }
    },
    onSuccess: (data) => {
      if (data.deleteSuggestion) {
        message.success("Амжилттай устгагдлаа");
        setDeleteId("");
        refetch();
      }
    },
  });

  const PortfolioIdToName = useMemo(() => {
    const IdToName: Record<string, string> = {};
    portfolio?.getAllPortfolios?.map(
      (item) => (IdToName[item._id ?? ""] = item.index + "")
    );
    return IdToName;
  }, [portfolio]);

  const cancelDelete = () => {
    return (
      <Col>
        <Row>Та устгахдаа итгэлтэй байна уу</Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 10,
          }}
        >
          <Button
            type="primary"
            danger
            style={{ width: "49%" }}
            onClick={() => mutate()}
            disabled={mutateLoading}
          >
            Тийм
          </Button>
          <Button
            type="primary"
            style={{ width: "49%" }}
            disabled={mutateLoading}
            onClick={() => setDeleteId("")}
          >
            Үгүй
          </Button>
        </div>
      </Col>
    );
  };

  return (
    <div>
      <div className="page-header">
        <h3>Зөвлөгөө жагсаалт</h3>
        <Space>
          <Link to="/suggest/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Зөвлөгөө нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            loading={isLoading || portfolioLoading}
            dataSource={data?.suggestions ?? []}
            bordered
            size="small"
            scroll={{ x: 900 }}
            rowKey={(item) => item._id}
            columns={[
              {
                title: "Гарчиг",
                dataIndex: "title",
                key: "title",
                render: (_, record) => <text>{record.title}</text>,
              },
              {
                title: "Дэлгэрэнгүй",
                dataIndex: "description",
                key: "description",
                render: (_, record) => <text>{record.description}</text>,
              },
              {
                title: "Холбоос",
                dataIndex: "url",
                key: "url",
                render: (_, record) => (
                  <a href={record.url ?? "#"}>{record.url}</a>
                ),
              },
              {
                title: "Дуусах огноо",
                dataIndex: "expireAt",
                key: "expireAt",
                render: (_, record) => (
                  <span>{format(record.expireAt, "yyyy-MM-dd")}</span>
                ),
              },
              {
                title: "Давтамж",
                dataIndex: "suggestionCount",
                key: "suggestionCount",
                render: (_, record) => <text>{record.suggestionCount}</text>,
              },
              {
                title: "Хувьцаа",
                dataIndex: "stocks",
                key: "stocks",
                render: (_, record) => (
                  <Row style={{ gap: 4 }}>
                    {record.stocks.map((stock, index) => (
                      <Tag key={index}>{stock}</Tag>
                    ))}
                  </Row>
                ),
              },
              {
                title: "Хувьцааны өөрчлөлт",
                dataIndex: "portfolioInfos",
                key: "portfolioInfos",
                render: (_, record) => (
                  <Col>
                    {record.portfolioInfos.map((item, index) => (
                      <Row
                        style={{
                          columnGap: 4,
                          marginBottom: 4,
                        }}
                        key={index}
                      >
                        <Tag>{PortfolioIdToName[item?.currentId ?? "-"]}</Tag>
                        <SwapOutlined />
                        <Tag>{PortfolioIdToName[item?.changeId ?? "-"]}</Tag>
                      </Row>
                    ))}
                  </Col>
                ),
              },
              {
                dataIndex: "actions",
                key: "actions",
                render: (_, record) => (
                  <Popover
                    trigger="click"
                    content={cancelDelete}
                    open={deleteId === record._id}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      onClick={() => setDeleteId(record._id)}
                    />
                  </Popover>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
