import { Redirect, Route, Switch } from "react-router-dom";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import { Role } from "../graphql";
import { BalancePage } from "../pages/balance";
import { BalanceDetailPage } from "../pages/balance/detail";
import { BalanceHistoryPage } from "../pages/balance/history";
import { BondPage } from "../pages/bond";
import { ConfigPage } from "../pages/Config";
import { DashboardPage } from "../pages/Dashboard";
import { FAQPage } from "../pages/faq";
import { ImagePage } from "../pages/Image";
import { KYCPage } from "../pages/kyc/KYC";
import { LoginPage } from "../pages/Login";
import { LogoutPage } from "../pages/Logout";
import { NotfoundPage } from "../pages/Notfound";
import { OrderPage } from "../pages/Order";
import { OrderListPage } from "../pages/orderlist";
import { ConfirmOrderListPage } from "../pages/orderlist/confirm";
import { PortfolioPage } from "../pages/portfolio";
import { AddPortfolioPage } from "../pages/portfolio/create";
import { PortfolioDetailPage } from "../pages/portfolio/detail";
import { SavingPage } from "../pages/saving";
import { StockPage } from "../pages/stock";
import { SurveyPage } from "../pages/survey";
import { AddSurveyPage } from "../pages/survey/create";
import { SurveyDetailPage } from "../pages/survey/detail";
import { UserPage } from "../pages/user";
import { getUserData, isLoggedIn } from "../utils/auth";
import { AddUserPage } from "../pages/user/add";
import { EditUserPage } from "../pages/user/edit";
import { BondAddPage } from "../pages/bond/add";
import { KycDetailsPage } from "../pages/kyc/detail/index";
import { EditBondsPage } from "../pages/bond/edit";
import { AddSavingsPage } from "../pages/saving/add";
import { EditSavingsPage } from "../pages/saving/edit";
import { AddStockPage } from "../pages/stock/add";
import { EditStockPage } from "../pages/stock/edit";
import { BalanceDetailsStockHistoryPage } from "../pages/balance/detail/stock";
import { BalanceDetailsBondHistoryPage } from "../pages/balance/detail/bond";
import { FAQEditPage } from "../pages/faq/Edit";

import { GroupDetailsPage } from "../pages/user/group-details";
import { BalanceDetailsSavingsHistoryPage } from "../pages/balance/detail/saving";
import { UserDeletionRequestPage } from "../pages/userDeletionRequest";
import { ActivityPage } from "../pages/activity";
import { AddActivityPage } from "../pages/activity/add";
import { CreateOrderListPage } from "../pages/orderlist/create";

import { FeeReportsPage } from "@/pages/reports/Fee";
import { InterestPayoutReportsPage } from "@/pages/reports/InterestPayout";

import { StockDividendsPage } from "@/pages/stock/dividends";
import { CreateStockDividendPage } from "@/pages/stock/dividends/create";

import { ContractPage } from "@/pages/kyc/contract";
import { RefundPage } from "@/pages/refund";
import { RefundCreatePage } from "@/pages/refund/create/RefundCreate";
import { RefundConfirm } from "@/pages/refund/confirm";
import { RefundListPage } from "@/pages/refund/list";
import { RefundDetail } from "@/pages/refund/detail";
import { SuggestPage } from "@/pages/suggest";
import { SuggestAddPage } from "@/pages/suggest/add";

export const AdminApp = () => {
  const userLoggedIn = isLoggedIn();
  if (userLoggedIn && getUserData()?.role === Role.Apex) {
    return (
      <div className="container">
        <Sidebar />
        <div className="container-body">
          <Header />
          <div className="container-content">
            <Switch>
              <Route exact component={DashboardPage} path="/" />
              <Route exact component={UserPage} path="/users" />

              <Route
                exact
                component={GroupDetailsPage}
                path="/users/group-details/:_id"
              />
              <Route exact component={OrderPage} path="/orders" />
              <Route exact component={BalancePage} path="/balances" />
              <Route
                exact
                component={CreateOrderListPage}
                path="/orderlists/create"
              />
              <Route exact component={OrderListPage} path="/orderlists" />
              <Route exact component={LogoutPage} path="/logout" />
              <Route exact component={NotfoundPage} />
              <Route exact component={SuggestPage} path="/suggest" />
              <Route exact component={SuggestAddPage} path="/suggest/add" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
  if (userLoggedIn && getUserData()?.role === Role.Operator) {
    return (
      <div className="container">
        <Sidebar />
        <div className="container-body">
          <Header />
          <div className="container-content">
            <Switch>
              <Route exact component={DashboardPage} path="/" />
              <Route exact component={UserPage} path="/users" />
              <Route exact component={AddUserPage} path="/users/add" />
              <Route exact component={EditUserPage} path="/users/edit/:_id" />
              <Route
                exact
                component={GroupDetailsPage}
                path="/users/group-details/:_id"
              />
              <Route exact component={OrderPage} path="/orders" />
              <Route exact component={BalancePage} path="/balances" />
              <Route
                exact
                component={BalanceDetailPage}
                path="/balancedetail/:_id"
              />
              <Route
                exact
                component={BalanceHistoryPage}
                path="/balanceHistory/:_id"
              />
              <Route exact component={KYCPage} path="/kycs" />
              <Route exact component={KycDetailsPage} path="/kycs/:_id" />
              <Route
                exact
                component={UserDeletionRequestPage}
                path="/userdeletionrequests"
              />
              <Route exact component={ActivityPage} path="/activity" />
              <Route exact component={AddActivityPage} path="/activity/add" />
              <Route exact component={OrderListPage} path="/orderlists" />
              <Route
                exact
                component={ConfirmOrderListPage}
                path="/orderlist/:_id"
              />
              <Route exact component={LogoutPage} path="/logout" />
              <Route exact component={NotfoundPage} />
              <Route exact component={SuggestPage} path="/suggest" />
              <Route exact component={SuggestAddPage} path="/suggest/add" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
  if (userLoggedIn && getUserData()?.role === Role.Admin) {
    return (
      <div className="container">
        <Sidebar />
        <div className="container-body">
          <Header />
          <div className="container-content">
            <Switch>
              <Route exact component={DashboardPage} path="/" />
              <Route exact component={UserPage} path="/users" />
              <Route exact component={AddUserPage} path="/users/add" />
              <Route exact component={EditUserPage} path="/users/edit/:_id" />
              <Route
                exact
                component={CreateOrderListPage}
                path="/orderlists/create"
              />
              <Route
                exact
                component={GroupDetailsPage}
                path="/users/group-details/:_id"
              />

              <Route exact component={BondPage} path="/bonds" />
              <Route exact component={BondAddPage} path="/bonds/add" />
              <Route exact component={EditBondsPage} path="/bonds/edit/:_id" />
              <Route exact component={ActivityPage} path="/activity" />
              <Route exact component={AddActivityPage} path="/activity/add" />
              <Route exact component={SurveyPage} path="/surveys" />
              <Route exact component={SavingPage} path="/savings" />
              <Route exact component={AddSavingsPage} path="/savings/add" />
              <Route
                exact
                component={EditSavingsPage}
                path="/savings/edit/:_id"
              />
              <Route exact component={BalancePage} path="/balances" />
              <Route exact component={ImagePage} path="/images" />
              <Route
                exact
                component={BalanceDetailPage}
                path="/balancedetail/:_id"
              />
              <Route
                exact
                component={BalanceDetailsStockHistoryPage}
                path="/balancedetail/stock/:_id/:index"
              />

              <Route
                exact
                component={BalanceDetailsBondHistoryPage}
                path="/balancedetail/bond/:_id/:index"
              />
              <Route
                exact
                component={BalanceDetailsSavingsHistoryPage}
                path="/balancedetail/savings/:_id/:index"
              />

              <Route
                exact
                component={BalanceHistoryPage}
                path="/balanceHistory/:_id"
              />

              <Route
                exact
                component={ConfirmOrderListPage}
                path="/orderlist/:_id"
              />
              <Route exact component={SuggestPage} path="/suggest" />
              <Route exact component={SuggestAddPage} path="/suggest/add" />
              <Route exact component={OrderListPage} path="/orderlists" />
              <Route exact component={RefundPage} path="/refund" />
              <Route exact component={RefundCreatePage} path="/refund/create" />
              <Route exact component={RefundListPage} path="/refund/list" />
              <Route
                exact
                component={RefundConfirm}
                path="/refund/confirm/:_id"
              />
              <Route
                exact
                component={RefundDetail}
                path="/refund/detail/:_id"
              />
              <Route
                exact
                component={InterestPayoutReportsPage}
                path="/reports/interest-payout"
              />
              <Route exact component={FeeReportsPage} path="/reports/fee" />

              <Route exact component={AddSurveyPage} path="/surveys/:_id" />
              <Route
                exact
                component={SurveyDetailPage}
                path="/surveydetail/:_id"
              />

              <Route exact component={StockPage} path="/stocks" />
              <Route exact component={AddStockPage} path="/stocks/add" />
              <Route exact component={EditStockPage} path="/stocks/edit/:_id" />
              <Route
                exact
                component={StockDividendsPage}
                path="/stocks/dividends"
              />
              <Route
                exact
                component={CreateStockDividendPage}
                path="/stocks/dividends/create"
              />

              <Route exact component={PortfolioPage} path="/portfolios" />
              <Route
                exact
                component={AddPortfolioPage}
                path="/portfolios/:_id"
              />
              <Route
                exact
                component={PortfolioDetailPage}
                path="/portfoliodetail/:_id"
              />
              <Route exact component={OrderPage} path="/orders" />
              <Route exact component={KycDetailsPage} path="/kycs/:_id" />
              <Route exact component={FAQPage} path="/faqs" />
              <Route component={FAQEditPage} path="/faqs/:id" />
              <Route exact component={ConfigPage} path="/configs" />
              <Route
                exact
                component={UserDeletionRequestPage}
                path="/userdeletionrequests"
              />

              <Route exact component={KYCPage} path="/kycs" />
              <Route exact component={ContractPage} path="/contracts" />

              <Route exact component={LogoutPage} path="/logout" />
              <Route exact component={NotfoundPage} />
            </Switch>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Route component={LoginPage} path="/login" />
        <Redirect to="/login" />
      </>
    );
  }
};
