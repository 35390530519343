import { FullLoading } from "@/components/FullLoading";
import { AddSuggestionInput, AddSuggestionMutationVariables } from "@/graphql";
import sdk from "@/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
} from "antd";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

export function SuggestAddWidget() {
  const [form] = Form.useForm();
  const history = useHistory();
  const { mutate, isLoading } = useMutation({
    mutationKey: ["addSuggest"],
    mutationFn: async (values: AddSuggestionInput) =>
      sdk.AddSuggestion({ suggestion: values }),
    onSuccess: (data) => {
      if (data.addSuggestion) {
        message.success("Амжилттай", 2);
        history.push("/suggest");
      }
    },
    onError: (err) => {
      if (err instanceof Error) {
        message.error(err.message, 2);
      }
    },
  });

  const { data, isLoading: portfolioLoading } = useQuery({
    queryKey: ["getPortfolio"],
    queryFn: async () => sdk.getAllPortfolios(),
  });

  const PortfolioIdToName = useMemo(() => {
    const IdToName: Record<string, string> = {};
    data?.getAllPortfolios?.map(
      (item) => (IdToName[item._id ?? ""] = item.index + "")
    );
    return IdToName;
  }, [data]);

  const onFinish = (values: any) => {
    const formattedValues = {
      ...values,
      expireAt: values.expireAt.valueOf(),
    };
    mutate(formattedValues);
  };

  return (
    <div>
      <div className="page-header">
        <h3>Зөвлөгөө нэмэх</h3>
      </div>
      <div className="page-body">
        {portfolioLoading ? (
          <div
            style={{
              marginTop: 50,
              justifySelf: "center",
            }}
          >
            <FullLoading />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            style={{ width: 500 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Гарчиг"
              name="title"
              rules={[{ required: true, message: "Гарчиг оруулна уу." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Тайлбар"
              name="description"
              rules={[{ required: true, message: "Тайлбар оруулна уу." }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label="Дуусах хугацаа"
              name="expireAt"
              rules={[
                { required: true, message: "Дуусах хугацааг сонгоно уу." },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Холбоос"
              name="url"
              rules={[
                {
                  required: true,
                  message: "YouTube холбоосыг оруулна уу.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.List name="portfolioInfos">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                  }}
                >
                  {fields.map((field) => (
                    <Space key={field.key}>
                      <Form.Item
                        noStyle
                        name={[field.name, "currentId"]}
                        rules={[
                          {
                            required: true,
                            message: "Багц заавал сонгоно уу",
                          },
                        ]}
                      >
                        <Select placeholder="Одоогийн багц">
                          {Object.entries(PortfolioIdToName).map(
                            ([id, name]) => (
                              <Select.Option key={id} value={id}>
                                {name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field.name, "changeId"]}
                        rules={[
                          {
                            required: true,
                            message: "Багц заавал сонгоно уу",
                          },
                        ]}
                      >
                        <Select placeholder="Өөрчлөх багц">
                          {Object.entries(PortfolioIdToName).map(
                            ([id, name]) => (
                              <Select.Option key={id} value={id}>
                                {name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Button
                        onClick={() => remove(field.name)}
                        danger
                        style={{ marginLeft: 10 }}
                        value="small"
                        type="primary"
                        icon={<MinusCircleOutlined />}
                      />
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => add()} block>
                    Багцын өөрчлөлт нэмэх
                  </Button>
                </div>
              )}
            </Form.List>
            <Form.List
              name="stocks"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("Дор хаяг нэг хувьцаа оруулна уу.")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <div style={{ marginTop: 20 }}>
                  {fields.map((field) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Хувьцаа оруулна уу эсвэл устгана уу",
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="Хувьцаа" style={{ width: "60%" }} />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <Button
                          onClick={() => remove(field.name)}
                          danger
                          style={{ marginLeft: 10 }}
                          value="small"
                          type="primary"
                          icon={<MinusCircleOutlined />}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Хувьцаа нэмэх
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </div>
              )}
            </Form.List>

            <Form.Item
              label="Давтамжын тоо"
              name="suggestionCount"
              rules={[{ required: true, message: "Тоог оруулна уу." }]}
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Илгээх
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
