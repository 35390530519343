import { message } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import sdk from "@/sdk";
import { Balance, PrepareOrderListResult } from "@/graphql";

export const useCreateOrderList = () => {
  const [loading, setLoading] = useState(false);
  const [usdRate, setUsdRate] = useState(0);
  const [balances, setBalances] = useState<Balance[]>([]);
  const [excelData, setExcelData] = useState<PrepareOrderListResult | null>(
    null
  );

  const [selectedPage, setSelectedPage] = useState(0);
  const { data, error, refetch, isLoading } = useQuery({
    queryKey: ["getAvailableBalances"],
    queryFn: () =>
      sdk.getAvailableBalances({
        page: 0,
        limit: 100000,
        options: { pagination: true },
        advanced: { isProcessing: false },
      }),
  });
  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationKey: ["getAvailableBalancesAll"],
    mutationFn: () =>
      sdk.getAvailableBalances({
        page: 0,
        limit: 40 * (data?.getAvailableBalances.totalPages ?? 0),
        options: { pagination: true },
        advanced: { isProcessing: false },
      }),
    onError: (e) => {
      if (e instanceof Error) {
        message.warn(e.message, 2);
      }
    },
    onSuccess: (data) => {
      setBalances(
        data.getAvailableBalances.balances.sort(
          (item, itemB) => item.cashAmount - itemB.cashAmount
        ) as Balance[]
      );
    },
  });
  useEffect(() => {
    if (error) {
      message.warn((error as any).response?.errors[0]?.message, 2);
    }
  }, [error]);

  const prepareOrderList = useCallback(() => {
    if (!balances.length) {
      message.warn("Хэрэглэгч сонгоно уу.", 2);
      return;
    }
    setLoading(true);
    sdk
      .prepareOrderList({
        balances: balances
          .filter((filteredBalance) => filteredBalance._id !== null)
          .map((balance) => String(balance._id)),
      })
      .then((data) => {
        setExcelData(data.prepareOrderList);
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [balances]);

  const createOrderList = useCallback(() => {
    if (!balances.length) {
      message.warn("Хэрэглэгч сонгоно уу.", 2);
      return;
    }
    if (!usdRate || usdRate < 0) {
      message.warn("Долларын ханш оруулна уу.", 2);
      return;
    }
    setLoading(true);
    sdk
      .createOrderList({
        balances: balances
          .filter((filteredBalance) => filteredBalance._id !== null)
          .map((balance) => String(balance._id)),
        usdRate,
      })
      .then((data) => {
        if (data.createOrderList) {
          message.success("Амжилттай үүслээ.");
          setBalances([]);
          setUsdRate(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [balances, usdRate]);

  return {
    data: data?.getAvailableBalances,
    loading: loading || isLoading || mutateLoading,
    usdRate,
    balances,
    excelData,
    selectedPage,
    setUsdRate,
    setBalances,
    setExcelData,
    getAvailableBalances: refetch,
    prepareOrderList,
    createOrderList,
    mutate,
    setSelectedPage,
  };
};
