import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import moment from "moment";
import { Button, Col, message, Popover, Row, Table } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import sdk from "@/sdk";
import { Contract } from "@/graphql";

import { ContractModel } from "./ContractModal";

export function ContractPage() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["contracts"],
    queryFn: () => sdk.getContracts(),
    select: (data) => data.contracts,
  });

  const [selectedContract, setSelectedContract] =
    useState<Partial<Contract> | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const { isLoading: mutateLoading, mutate } = useMutation({
    mutationKey: ["deleteContract"],
    mutationFn: () => sdk.deleteContract({ name: deleteId ?? "" }),
    onSuccess: () => {
      message.success("Амжилттай устгагдлаа");
      setDeleteId(null);
      refetch();
    },
    onError: (err) => {
      if (err instanceof Error) {
        message.error(err.message);
        setDeleteId(null);
      }
    },
  });

  const cancelRefund = () => {
    return (
      <Col>
        <Row>Та устгахдаа итгэлтэй байна уу</Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 10,
          }}
        >
          <Button
            type="primary"
            danger
            style={{ width: "49%" }}
            onClick={() => mutate()}
            disabled={mutateLoading}
          >
            Тийм
          </Button>
          <Button
            type="primary"
            style={{ width: "49%" }}
            disabled={mutateLoading}
            onClick={() => setDeleteId(null)}
          >
            Үгүй
          </Button>
        </div>
      </Col>
    );
  };

  return (
    <main className="page">
      <ContractModel
        selectedContract={selectedContract}
        onClose={() => setSelectedContract(null)}
      />
      <div className="page-header">
        <h3>Гэрээ</h3>
        <Button type="primary" onClick={() => setSelectedContract({})}>
          Гэрээ нэмэх
        </Button>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table loading={isLoading} dataSource={data}>
            <Table.Column title="Нэр" dataIndex="name" />
            <Table.Column
              title="Үүсгэсэн огноо"
              dataIndex="createdAt"
              render={(value) => moment(value).format("YYYY.MM.DD HH:mm")}
            />
            <Table.Column
              title="Шинэчлэгдсэн огноо"
              dataIndex="updatedAt"
              render={(value) => moment(value).format("YYYY.MM.DD HH:mm")}
            />
            <Table.Column
              dataIndex="actions"
              render={(_, record: Contract) => (
                <Popover
                  trigger="click"
                  content={cancelRefund}
                  open={deleteId === record.name}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    danger
                    icon={<DeleteFilled />}
                    onClick={() => setDeleteId(record.name)}
                  />
                </Popover>
              )}
            />
          </Table>
        </div>
      </div>
    </main>
  );
}
