import { Button, Form, message, Modal, Select } from "antd";

import { Dispatch, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import sdk from "@/sdk";
import { User } from "@/graphql";

const { Option } = Select;

export function EditUserPortfolioWidget({
  show,
  setShow,
  user,
}: {
  show: boolean;
  setShow: Dispatch<boolean>;
  user: User | null;
}) {
  const { data, isLoading } = useQuery({
    queryKey: ["all-portfolios"],
    queryFn: () => sdk.getAllPortfolios(),
    select: (data) => data.getAllPortfolios,
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: ({
      userId,
      portfolioId,
    }: {
      userId: string;
      portfolioId: string;
    }) =>
      sdk
        .editUserPortfolio({ _id: userId, portfolioId })
        .then((res) => {
          if (!res.editUserPortfolio) {
            throw new Error("Багц өөрчлөхөд алдаа гарлаа");
          }
          if (res.editUserPortfolio) {
            message.success("Амжилттай солигдлоо.");
            setShow(false);
          }
          queryClient.invalidateQueries({ queryKey: ["users"] });
        })
        .catch((err) => {
          if (err instanceof Error) {
            message.warn(err.message, 2);
          }
        }),
  });

  const [form] = Form.useForm();
  useEffect(() => {
    if (show) {
      form.setFields([
        { name: "portfolioId", value: user?.chosenPortfolio?._id },
        { name: "userId", value: user?._id },
      ]);
    }
  }, [form, show, user?._id, user?.chosenPortfolio?._id]);

  return (
    <Modal
      width={500}
      title="Багц өөрчлөх"
      open={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" disabled={isLoading} onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={isLoading}
          type="primary"
          loading={isLoading}
          onClick={() => form.validateFields().then(mutate)}
        >
          Хадгалах
        </Button>,
      ]}
    >
      <Form form={form} onFinish={mutate} layout="vertical" autoComplete="off">
        <Form.Item name="userId" label="Хэрэглэгч" required>
          <span>{`${user?.phoneNo} - ${user?.email}`}</span>
        </Form.Item>
        <Form.Item name="portfolioId" label="Багц" required>
          <Select
            showSearch
            allowClear
            loading={isLoading}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option!.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option!.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                0
            }
          >
            {data?.map((portfolio) => (
              <Option key={portfolio._id!} value={portfolio._id!}>
                {portfolio.localizedName?.mn}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
