import {
  UserOutlined,
  DollarOutlined,
  ProfileOutlined,
  CheckSquareOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  OrderedListOutlined,
  PictureOutlined,
  UsergroupDeleteOutlined,
  BarChartOutlined,
  NotificationOutlined,
  FileExcelOutlined,
  BarsOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import { Role } from "@/graphql";

export const categories = [
  {
    key: "parent1",
    name: "Хяналтын самбар",
    path: "/",
    role: [Role.Admin, Role.Operator, Role.Apex],
    icon: <BarChartOutlined />,
  },
  {
    key: "customers",
    name: "Харилцагчид",
    path: "/",
    role: [Role.Admin, Role.Operator, Role.Apex],
    icon: <UserOutlined />,
    subCategories: [
      {
        key: "users-list",
        name: "Харилцагчдын мэдээлэл",
        path: "/users",
        role: [Role.Admin, Role.Operator, Role.Apex],
      },
      {
        key: "parent9",
        name: "Хэрэглэгч устгах хүсэлт",
        path: "/userdeletionrequests",
        icon: <UsergroupDeleteOutlined />,
        role: [Role.Admin, Role.Operator],
      },
    ],
  },
  {
    key: "user-verification",
    name: "Баталгаажуулалт",
    path: "/kycs",
    icon: <CheckSquareOutlined />,
    role: [Role.Admin, Role.Operator],
    subCategories: [
      {
        key: "parent5",
        name: "KYC",
        path: "/kycs",
        icon: <CheckSquareOutlined />,
        role: [Role.Admin, Role.Operator],
      },
      {
        key: "contracts",
        name: "Гэрээ",
        path: "/contracts",
        role: [Role.Admin],
      },
    ],
  },
  {
    key: "parent2",
    name: "Багц",
    path: "/portfolio",
    role: [Role.Admin],
    subCategories: [
      {
        key: "sub3",
        name: "Бонд",
        path: "/bonds",
        role: [Role.Admin],
      },
      {
        key: "sub4",
        name: "Үнэт цаас",
        path: "/stocks",
        role: [Role.Admin],
      },
      {
        key: "sub5",
        name: "Үл хөдлөх хөрөнгө",
        path: "/savings",
        role: [Role.Admin],
      },
      {
        key: "sub6",
        name: "Багцын задаргаа",
        path: "/portfolios",
        role: [Role.Admin],
      },
    ],
    icon: <OrderedListOutlined />,
  },
  {
    key: "parent3",
    name: "Хөрөнгө оруулалт",
    path: "/investments",
    role: [Role.Admin, Role.Operator],
    subCategories: [
      {
        key: "sub7",
        name: "Цэнэглэлт",
        path: "/orders",
        role: [Role.Admin, Role.Operator],
      },
      {
        key: "sub8",
        name: "Захиалгын хуудас",
        path: "/orderlists",
        role: [Role.Admin, Role.Operator],
      },
      {
        key: "sub9",
        name: "Данс",
        path: "/balances",
        role: [Role.Admin, Role.Operator],
      },
      {
        key: "dividends-stock",
        name: "Хувьцааны ноогдол ашиг",
        path: "/stocks/dividends",
        role: [Role.Admin, Role.Operator],
      },
    ],
    icon: <DollarOutlined />,
  },
  {
    key: "refund",
    name: "Буцаалт",
    role: [Role.Admin],
    icon: <BarsOutlined />,
    subCategories: [
      {
        key: "refundPage",
        name: "Буцаалтын захиалга",
        path: "/refund",
        role: [Role.Admin],
      },
      {
        key: "refundList",
        name: "Буцаалтын жагсаалт",
        path: "/refund/list",
        role: [Role.Admin],
      },
    ],
  },
  {
    key: "reports",
    name: "Тайлан",
    path: "/reports",
    role: [Role.Admin],
    icon: <FileExcelOutlined />,
    subCategories: [
      {
        key: "interest-payout",
        name: "Хүү олголт",
        path: "/reports/interest-payout",
        role: [Role.Admin],
      },
      {
        key: "fee",
        name: "Шимтгэл",
        path: "/reports/fee",
        role: [Role.Admin],
      },
    ],
  },
  {
    key: "parent4",
    name: "Судалгаа",
    path: "/surveys",
    icon: <ProfileOutlined />,
    role: [Role.Admin],
  },

  {
    key: "parent6",
    name: "FAQ",
    path: "/faqs",
    icon: <QuestionCircleOutlined />,
    role: [Role.Admin],
  },
  {
    key: "parent7",
    name: "Тохиргоо",
    path: "/configs",
    icon: <SettingOutlined />,
    role: [Role.Admin],
  },
  {
    key: "parent8",
    name: "Зурагнууд",
    path: "/images",
    icon: <PictureOutlined />,
    role: [Role.Admin],
  },

  {
    key: "parent10",
    name: "Мэдэгдэл",
    path: "/activity",
    icon: <NotificationOutlined />,
    role: [Role.Admin, Role.Operator],
  },
  {
    key: "parent11",
    name: "Зөвлөгөө",
    path: "/suggest",
    icon: <ContainerOutlined />,
    role: [Role.Admin, Role.Operator],
  },
];
