import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Button, message, Popconfirm, Space, Table, Tag } from "antd";
import {
  FileAddOutlined,
  FileProtectOutlined,
  EyeOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { WithdrawalOrderList, WithdrawalStatus } from "@/graphql";
import sdk from "@/sdk";
import { useGenerateExcel } from "./RefundExcel";

type RefundWidgetProps = {
  status: WithdrawalStatus;
};

function DownloadOrderListButton({
  data,
  status,
}: {
  data: WithdrawalOrderList;
  status: WithdrawalStatus;
}) {
  const { generateExcel, loading } = useGenerateExcel();

  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationFn: async () =>
      sdk
        .downloadWithdrawalConfirmationReport({ orderListId: data._id })
        .then((res) => {
          window.open(res.downloadWithdrawalConfirmationReport, "_blank");
        })
        .catch((e) => message.error((e as Error).message)),
  });

  return (
    <div style={{ display: "flex" }}>
      <Space>
        <Button
          onClick={() => generateExcel(data)}
          loading={loading}
          disabled={loading}
        >
          Татаж авах
        </Button>
        {status === WithdrawalStatus.Done && (
          <Button
            onClick={() => mutate()}
            loading={mutateLoading}
            disabled={mutateLoading}
          >
            Дэлгэрэнгүй эксел татах
          </Button>
        )}
      </Space>
    </div>
  );
}

export function RefundWidget({ status }: RefundWidgetProps) {
  const history = useHistory();
  const [page, setPage] = useState(0);

  const { data, isLoading, refetch } = useQuery(
    ["getWithdrawalOrderLists", status, page],
    async () => sdk.withdrawalOrderLists({ limit: 30, page: page, status }),
    {
      select: (data) => data.withdrawalOrderLists,
    }
  );

  const { mutate: cancelMutate, isLoading: cancelLoading } = useMutation({
    mutationFn: async (id: string) =>
      sdk
        .cancelWithdrawalOrderList({ cancelWithdrawalOrderListId: id })
        .then((res) => {
          if (!res.cancelWithdrawalOrderList) {
            throw new Error("Алдаа");
          }
          message.success("Амжилттай");
          refetch();
        })
        .catch((e) => {
          if (e instanceof Error) {
            message.error(e.message);
          }
        }),
  });

  return (
    <div>
      <div className="page-header">
        <h3>Буцаалтын хуудсууд</h3>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              loading={isLoading}
              disabled={isLoading}
              style={{ marginTop: 10 }}
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => history.push("refund/create")}
            >
              Буцаалтын хуудас үүсгэх
            </Button>
          </Space>
        </div>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            size="small"
            bordered
            rowKey={(row) => row._id}
            pagination={{
              pageSize: 30,
              total: data?.totalItems,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            loading={isLoading}
            dataSource={data?.data ?? []}
            columns={[
              {
                title: "№",
                dataIndex: "number",
                key: "number",
                render: (_, __, index) => <span>{index + 1}</span>,
              },
              {
                title: "Төлөв",
                dataIndex: "status",
                key: "status",
                render: (_, record) => <Tag>{record.status}</Tag>,
              },
              {
                title: "Нийт буцаалтын тоо",
                dataIndex: "totalPurchases",
                key: "totalPurchases",
                render: (_, record) => (
                  <span>{record.withdrawalRequests.length ?? 0}</span>
                ),
              },
              {
                title: "Үүсгэсэн огноо",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (_, record) => (
                  <span>
                    {moment(record.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                  </span>
                ),
              },
              {
                title: "Шинэчлэгдсэн огноо",
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (_, record) => (
                  <span>
                    {record.updatedAt
                      ? moment(record.updatedAt).format("YYYY/MM/DD HH:mm:ss")
                      : "-"}
                  </span>
                ),
              },
              {
                title: "",
                dataIndex: "download",
                key: "download",
                render: (_, record) =>
                  record._id && (
                    <DownloadOrderListButton
                      data={record as WithdrawalOrderList}
                      status={status}
                    />
                  ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      {status === WithdrawalStatus.Processing && (
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() => {
                            history.push(`/refund/confirm/${record._id}`);
                          }}
                          icon={<FileProtectOutlined />}
                        />
                      )}
                      {status === WithdrawalStatus.Processing && (
                        <Popconfirm
                          title="Та цуцлахдаа итгэлтэй байна уу?"
                          okText="Тийм"
                          cancelText="Үгүй"
                          onConfirm={() => cancelMutate(record._id)}
                          disabled={cancelLoading}
                        >
                          <Button
                            type="primary"
                            danger
                            shape="circle"
                            onClick={() => {}}
                            icon={<UndoOutlined />}
                            disabled={cancelLoading}
                            loading={cancelLoading}
                          />
                        </Popconfirm>
                      )}
                      {status === WithdrawalStatus.TransferPending && (
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() =>
                            history.push(`/refund/detail/${record._id}`)
                          }
                          icon={<EyeOutlined />}
                        />
                      )}
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
